<template>
<!--wrap game tiles in a header for seo. Ensure they remain accessible!-->
<!--eslint-disable-next-line vuejs-accessibility/heading-has-content-->
<h3 class="game-tile" :alt="game?.title" :aria-label="game?.title" tabIndex="0">
    <NuxtLink :to="gameNav">
        <div class="images-container game">
            <div v-if="game?.matureRating" class="ratings-icon">
                <RatingsIcon />
            </div>
            <img
                :alt="game?.title" class="game-img" :src="imageSrc"
                aria-hidden
                focusable="false"
            >
        </div>
    </NuxtLink>
</h3>
</template>

<script lang="ts">
import { type PropType } from 'vue'
import type { RouteLocation } from 'vue-router'
import RatingsIcon from '$components/ratings/RatingsIcon.vue'
import type { JBGWeb } from '$types/JBGWeb'

export default defineNuxtComponent({
    components: { RatingsIcon },

    props: {
        game: {
            type: Object as PropType<JBGWeb.GameDetail>
        },
        imageWidthOnRequest: {
            type: Number,
            required: false
        }
    },

    data() {
        return {
            windowWidth: null // window.innerWidth
        }
    },

    computed: {
        imageSrc(): string | undefined {
            if (this.game?.tile?.data) {
                return `${this.game.tile.data.attributes?.url}?format=auto&width=${this.reqImageWidth}`
            }

            // no tile? Default to hero for now
            if (this.game?.hero?.data) {
                return `${this.game?.hero?.data?.attributes.url}?format=auto&width=${this.reqImageWidth}`
            }

            return undefined
        },

        reqImageWidth(): number {
            return 500
        },

        gameNav(): RouteLocation {
            if (!this.game.packDetails?.data) {
                return this.localeRoute({
                    name: 'games-gameslug',
                    params: {
                        gameslug: this.game.slug
                    }
                })
            }
            return this.localeRoute({
                name: 'games-packs-packslug-gameslug',
                params: {
                    packslug: this.game.packDetails?.data?.attributes.slug,
                    gameslug: this.game.slug
                }
            })
        }
    },

    mounted() {
        if (import.meta.client) {
            this.onResize()
            window.addEventListener('resize', this.onResize as UIEvent)
        }
    },

    beforeUnmount() {
        window.removeEventListener('resize', this.onResize as UIEvent)
    },

    methods: {
        onResize() {
            this.windowWidth = window.innerWidth
        }
    }
})

</script>

<style lang="scss" scoped>
@use "$styles/kit.scss" as *;

.game-tile {
    cursor: pointer;
    // negate the h3 styling
    margin: 0;
    padding: 0;
    margin-block-start: 0;
    margin-block-end: 0;
}

.images-container {
    // allows for ratings icon positioning
    position: relative;
    display: flex;

    &:hover {
        border-radius: 4px;
        outline: solid 3px var(--primary-50);
        outline-offset: -1.5px;
        transform: scale(1.05, 1.05);
        transition: transform 0.2s;
    }

    .game-img {
        border-radius: 4px;
        box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.10), 0px 4px 5px -1px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.20);
        transition: transform .2s;
        width: 100%;
        height: auto;
        max-width: 100%;
    }

    .ratings-icon {
        width: auto;
        height: 50px;
        top: 10px;
        right: 10px;
        z-index: 2;

        @include mq-large-and-more {
            height: 60px;
        }

        @include mq-medium {
            height: 40px;
            top: 8px;
            right: 8px;
        }

        @include mq-small {
            height: 36px;
            top: 6px;
            right: 6px;
        }

        @include mq-xsmall{
            height: 30px;
            top: 4px;
            right: 4px;
        }
    }
}
</style>
